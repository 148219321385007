<template>
  <div class="relative inline-block w-full">
    <label
      v-if="$slots.label && labeltop"
      :for="id"
      class="mb-1 block font-semibold"
    >
      <slot name="label" />
    </label>
    <template v-if="$slots.icon">
      <span class="absolute top-0 left-0 py-2 ml-3 h-full flex items-center">
        <slot name="icon" />
      </span>
    </template>
    <input
      :id="id"
      :type="type"
      :name="name"
      :title="title"
      :value="props.modelValue"
      :class="[{'pl-11 pr-6': $slots.icon},
               {'px-4': !$slots.icon && !xs},
               {'py-3 h-[51.59px]': normal && !$slots.label},
               {'py-2 ': slim && !$slots.label},
               {'py-1 px-1': xs && !labeltop && !$slots.icon},
               {'py-3 text-base h-[55px] peer': normal && labeltop && $slots.label},
               {'py-3 text-base peer': slim && labeltop && $slots.label},
               {'pt-6 pb-1 text-lg font-semibold h-[55px] peer': normal && labelinside && $slots.label},
               {'pt-3 pb-1 text-base font-semibold peer': slim && labelinside && $slots.label},
               {'rounded-xl w-full': !props.inputstyle},
               {'border-neutral-200 bg-white': !error},
               {'border-cta-700 bg-cta-50': error},
               'border text-left focus:outline-none focus:border-petrol-500 focus:ring-1 focus:ring-petrol-500 block',
               props.inputstyle]"
      :placeholder="props.placeholder"
      :aria-label="props.accessibilityLabel || props.title || props.name"
      :autocomplete="autocomplete ? 'on' : 'off'"
      @input="$emit('update:modelValue', $event.target.value)"
    >
    <label
      v-if="$slots.label && labelinside"
      :for="id"
      :class="[ {'absolute -mt-3 ml-4': labelup},
                {'absolute text-lg text-petrol-500 font-semibold duration-200 transform -translate-y-3.5 scale-[75%] top-4 z-10 origin-[0] left-11 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1 peer-focus:scale-[75%] peer-focus:-translate-y-3.5': slim && !labelup},
                {'absolute text-lg text-petrol-500 font-semibold duration-200 transform -translate-y-2.5 scale-[85%] top-4 z-10 origin-[0] left-11 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-[85%] peer-focus:-translate-y-2.5': normal && !labelup},
                'left-0']"
    >
      <slot name="label" />
    </label>
  </div>
</template>
<script setup lang="ts">
import { getRandomId } from '~/utils/Utils'
const props = defineProps({
  design: {
    type: String,
    default: 'normal'
  },
  label: {
    type: String,
    default: ''
  },
  labelPosition: {
    type: String,
    default: 'inside'
  },
  accessibilityLabel: {
    type: String,
    default: ''
  },
  id: {
    type: String,
    default: getRandomId
  },
  inputstyle: {
    type: [String, Array],
    default: ''
  },
  placeholder: {
    type: String,
    default: ' '
  },
  name: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'text'
  },
  title: {
    type: String,
    default: ''
  },
  modelValue: {
    type: String,
    default: ''
  },
  error: {
    type: Boolean,
    default: false
  },
  autocomplete: {
    type: Boolean,
    default: true
  }
})
const normal = computed(() => props.design === 'normal')
const slim = computed(() => props.design === 'slim')
const xs = computed(() => props.design === 'xs')

// Label choices
const labelup = computed(() => props.label === 'up')
const labeltop = computed(() => props.labelPosition === 'top')
const labelinside = computed(() => props.labelPosition === 'inside')

defineEmits<{(e: 'update:modelValue', value: string): void}>()
</script>
